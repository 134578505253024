<template>
  <div>
    <a
      data-toggle="tooltip"
      data-placement="top"
      title="Resend activation link"
      @click="fnShowModal()"
      class="p-2"
      ><i class="far fa-share-square text-info"></i
    ></a>

    <b-modal ref="userModal" hide-footer title="Resend activation link">
      <div class="d-block">
        <p>
          Do you need to resend activation link to: {{ userData.first_name }} ?
        </p>
        <form v-on:submit.prevent="fnSend">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-secondary btn-sm mr-2"
              @click="hideModal"
            >
              No
            </button>
            <button type="submit" class="btn btn-info btn-sm">
              <b-spinner small v-if="isLoading" class="mr-2"></b-spinner>
              Yes
            </button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as messageService from "../../../services/message.service";
import * as authService from "../../../services/auth.service";
export default {
  name: "ModalResendActivationLink",
  props: ["propsData"],
  components: {},
  data() {
    return {
      errors: {},
      isLoading: false,
      userData: {
        id: "",
        first_name: "",
      },
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(["fetchUsersAdminAll"]),
    fnShowModal() {
      this.fnGetValue(this.propsData);
      this.showModal();
    },
    hideModal() {
      this.$refs.userModal.hide();
    },
    showModal() {
      this.$refs.userModal.show();
    },

    fnGetValue(props) {
      this.userData.id = props.id;
      this.userData.first_name = props.first_name;
    },

    fnSend: async function () {
      this.errors = {};

      try {
        this.isLoading = true;
        const res = await authService.resendActivationLink(this.userData.id);

        if (res.status == 200) {
          this.fetchUsersAdminAll();
          messageService.fnSweetAlertSuccessToast(
            "Resend activation link - Successfully send"
          );
          this.hideModal();
        }
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Resend activation link",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Resend activation link",
              "Something wrong"
            );
            break;
        }

        this.isLoading = true;
        console.log("Error:fnSend");
      }
    },
  },
};
</script>